@font-face {
  font-family: 'Graphik';
  src: url('./Graphik-Light-Web.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Graphik';
  src: url('./Graphik-Medium-Web.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Graphik';
  src: url('./Graphik-Regular-Web.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Plantin MT Pro';
  src: url('./Plantin-MT-Pro-Light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
}
